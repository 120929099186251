import React, { useState } from "react";
import { graphql, Link as GatsbyLink } from "gatsby";
import { Layout } from "/src/components/layouts/default";
import Seo from "/src/components/helpers/seo";
import styled from "styled-components";
import FilterComponent from "/src/components/helpers/FilterComponent/FilterComponent";

const StyledLink = styled(GatsbyLink)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid gray;
  text-decoration: none;
  .title {
    color: black;
    padding: 10px 0;
    font-size: 16px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin-bottom:50px;
  @media(max-width:600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  img {
    max-width: 100%;
    height: auto;
  }
`;

const IndexPage = ({ data }) => {
  const articles = data.allNodeArticle.nodes;
  const [selectedTags, setSelectedTags] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState(articles);

  let tagsTemps = [];
  const allTagsObjects = articles.map((item) => item.relationships.field_tags);
  for (const element of allTagsObjects) {
    tagsTemps = [...tagsTemps, element[0].name];
  }
  const allTags = [...new Set(tagsTemps)];

  const handleSelect = (tag) => {
    const isSelected = selectedTags.includes(tag);
    const newSelection = isSelected
      ? selectedTags.filter((currentTag) => currentTag !== tag)
      : [...selectedTags, tag];
    setSelectedTags(newSelection);
  };

  const applyFilters = () => {
    console.log(selectedTags);
    if (selectedTags.length > 0) {
      const filtered = articles.filter((article) => {
        const tags = article.relationships.field_tags[0].name;
        return selectedTags.some((f) => tags.includes(f));
      });
      setFilteredArticles(filtered);
    } else {
      setFilteredArticles(articles);
    }
  };

  return (
    <Layout>
      <Seo title="Index EN" />
      <h1>List of articles</h1>
      <div className="twoColumns">
        <div className="articlesGrid">
          <FilterComponent label="Tags" onSelect={applyFilters}>
            <div className="tags-list">
              {allTags.map((tag, index) => {
                const isSelected = selectedTags.includes(tag);
                return (
                  <label key={index}>
                    <input
                      type="checkbox"
                      checked={isSelected}
                      onChange={() => handleSelect(tag)}
                    />
                    <span className="ml-2 text-base text-gray-500 font-heading">
                      {tag}
                    </span>
                  </label>
                );
              })}
            </div>
          </FilterComponent>

          <Grid>
            {filteredArticles.map((article) => (
              <StyledLink key={article.path.alias} to={article.path.alias}>
                <img
                  src={
                    article.relationships.field_image.image_style_uri
                      .square_500x500
                  }
                  alt={article.title}
                />
                <span className="title">{article.title}</span>
              </StyledLink>
            ))}
          </Grid>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ArticlesListsEN {
    allNodeArticle(filter: { langcode: { eq: "en" } }) {
      nodes {
        id
        body {
          processed
        }
        path {
          alias
        }
        title
        relationships {
          field_tags {
            name
            drupal_internal__tid
          }
          field_image {
            image_style_uri {
              square_500x500
            }
          }
        }
      }
    }
  }
`;
