import React, { useState, useRef, useEffect } from "react";
import { FilterWrapper, Filterbutton, FilterDropdown } from "./FilterComponent.styles";
import FilterModalComponent from "./FilterModalComponent/FilterModalComponent";
 
const FilterComponent = ({children, label, onSelect}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(undefined);
  const buttonRef = useRef(undefined);
  const modalRef = useRef(undefined);

  useEffect(() => {
    const handleClick = (event) => {
      const isDropdownClicked =
        dropdownRef.current && dropdownRef.current.contains(event.target);
      const isButtonClicked =
        buttonRef.current && buttonRef.current.contains(event.target);
      const isModalClicked =
        modalRef.current && modalRef.current.contains(event.target);

      if (isDropdownClicked || isButtonClicked || isModalClicked) {
        // We would do nothing if the ref is undefined or user clicks on menu.
        return;
      }

      // Or else close the menu.
     setIsOpen(false);
    };

    document.addEventListener("mousedown", handleClick);
    document.addEventListener("touchstart", handleClick);

    // cleanup
    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("touchstart", handleClick);
    };
  }, [dropdownRef, buttonRef, modalRef]);

  const handleSelect = () => {
    onSelect()
    setIsOpen(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <FilterWrapper>
      <Filterbutton ref={buttonRef} onClick={toggle}>{ label }</Filterbutton>
      {isOpen && (
        <FilterDropdown ref={dropdownRef}>
          <div>
          {children}
            <div className="filter_dropdown_actions">
              <button
                onClick={() => handleSelect()}
                className="filter_dropdown_button"
              >
               Apply
              </button>
            </div>
          </div>
        </FilterDropdown>
      )}

      {isOpen && (
        <FilterModalComponent
          ref={modalRef}
          onSelect={() => handleSelect()}
          onCancel={() => setIsOpen(false)}
        >
          {children}
        </FilterModalComponent>
      )}
    </FilterWrapper>
  );
};

export default FilterComponent;
